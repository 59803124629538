<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  name: 'UserDashboard',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Dashboard'
    }
  },

  data () {
    return {
      request_pending: 0,
      request_approval: 0
    }
  },

  mounted () {
    core.index()
    this.fetchData()
  },

  methods: {
    async fetchData () {
    }
  }
}
</script>
